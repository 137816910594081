import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  width: 100%;
  .date-range {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  .date {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 4rem;
  }
  .day {
    margin-bottom: 1.7rem;
  }
  .table {
    margin-top: .75rem;
    width: 100%;
    .thead {
      border-bottom: .1rem solid var(--border);
      border-top: .1rem solid var(--border);
      display: flex;
      .th {
        color: var(--text);
        font-size: 1.4rem;
        padding: 1rem 0;
        text-align: left;
        text-transform: uppercase;
        width: 20%;
        &:nth-of-type(1) {
          width: 40%;
        }
        &:nth-of-type(2) {
          /* margin-left: 1rem; */
        }
        @media only screen and (max-width: 768px) {
          width: 50%;
          &:nth-of-type(1) {
            /* margin-left: 0.5rem; */
            width: 48%;
          }
          &:nth-of-type(2) {
            padding-left: 20%;
          }
          &:last-of-type {
            display: none;
          }
        }
      }
      .tr {
        display: flex;
        width: 100%;
      }
    }
    .tbody {
      .tr {
        display: flex;
        font-size: 1.6rem;
        font-weight: bold;
        &:nth-of-type(even) {
          background-color: var(--secondary-background);
        }
        .td {
          align-items: center;
          color: var(--text);
          display: flex;
          img {
            max-width: 2rem;
            @media only screen and (max-width: 768px) {
              display: none;
            }
          }
        }
        .td:nth-of-type(1) {
          padding-left: 0.5rem;
          width: 40%;
        }
        .td {
          padding: 1rem 0;
          width: 20%;
          .name {
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
            @media only screen and (max-width: 880px) {
              /* font-size: 1.2rem; */
            }
          }
          strong {
            margin: 0 1rem;
            @media only screen and (max-width: 880px) {
              margin: 0 0.5rem;
              font-size: 1.2rem;
            }
          }
          * {
            /* color: var(--text);
            display: inline-block; */
            /* margin: 0 0.2rem; */
          }
          .name.mobile {
            display: none;
          }
          &:last-of-type {
            display: flex;
            @media only screen and (max-width: 768px) {
              justify-content: space-around;
              padding: 0 0 0.5rem;
            }
          }
          .results {
            margin-left: 5rem;
            background-color: var(--primary);
            color: white !important;
            font-size: 1.2rem;
            font-weight: 400;
            margin: 0.25rem 0 0.25rem 3rem;
            max-width: 16rem;
            padding: 0.5rem 1.8rem;
            -webkit-text-decoration: none;
            text-decoration: none;
            span {
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 9rem;
              i {
                color: white;
                padding-right: .75rem;
              }
            }
          }
          .link a {
            transition: color .2s ease-in-out;
            &:hover {
              color: var(--primary);
            }
          }
          @media only screen and (max-width: 768px) {
            font-size: 1.4rem;
            text-align: center;
            width: 47%;
            &.td:nth-of-type(1) {
              width: 47%;
            }
            &.td:nth-of-type(2) {
              display: flex;
              justify-content: center;
            }
            .name {
              display: none;
              &.mobile {
                display: inline-block;
              }
            }
          }
        }
        @media only screen and (max-width: 768px) {
          display: flex;
          flex-wrap: wrap;
          .links {
            align-items: space-around;
            display: flex;
            width: 100%;
            .link {
              background-color: var(--primary);
              box-sizing: border-box;
              text-align: center;
              max-width: 48%;
              width: 48%;
              &.full {
                max-width: none;
                width: 100%;
              }
              .results {
                display: flex;
              }
            }
          }
        }
      }
      tr:nth-child(even) {
        background: var(--border);
      }
    }
    .link {
      font-size: 1.2rem;
      font-weight: 400;
      text-decoration: none;
      /* &:hover {
        text-decoration: underline;
      } */
      .twitch {
        color: var(--text);
        font-size: 1.6rem;
        font-weight: bold;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .link {
      align-items: center;
      color: var(--background);
      display: flex;
      justify-content: center;
      padding: 0.5rem 0;
    }
    .table .tbody .tr {
      .links .link {
        span, i {
          color: var(--text);
        }
        a {
          color: var(--text);
        }
        &.results {
          margin-left: 0;
        }
        .twitch {
          color: var(--primary);
          font-size: 1.2rem;
          font-weight: 400;
          text-decoration: none;
        }
      }
    }
  }
`;